<template>
  <iframe
    src="https://datakrewtech.retool.com/apps/713702ec-4e1b-11ee-93da-ff5846d9938e/Home"
    frameborder="0"
    style="width: 100%; height: 100vh"
  ></iframe>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
